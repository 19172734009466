@import url('https://rsms.me/inter/inter.css');

/**
 * Main wrapper
 */
.select-search-container {
    --select-search-background: #fff;
    --select-search-border: #dce0e8;
    --select-search-selected: #69a6f1;
    --select-search-text: #000;
    --select-search-subtle-text: #6c6f85;
    --select-search-inverted-text: var(--select-search-background);
    --select-search-highlight: #eff1f5;
    --select-search-font: 'Inter', sans-serif;

    width: 100%;
    position: relative;
    color: var(--select-search-text);
    box-sizing: border-box;
}

body.is-dark-mode .select-search-container {
    --select-search-background: #000;
    --select-search-border: #313244;
    --select-search-selected: #69a6f1;
    --select-search-text: #fff;
    --select-search-subtle-text: #a6adc8;
    --select-search-highlight: #1e1e2e;
}

body.is-light-mode .select-search-container {
    --select-search-background: #fff;
    --select-search-border: #dce0e8;
    --select-search-selected: #69a6f1;
    --select-search-text: #000;
    --select-search-subtle-text: #6c6f85;
    --select-search-highlight: #eff1f5;
}

.select-search-container *,
.select-search-container *::after,
.select-search-container *::before {
    box-sizing: inherit;
}




.select-search-input {
    position: relative;
    z-index: 1;
    display: block;
    height: 45px;
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #69a6f1;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0px 5px 0px 10px;
    background: var(--select-search-background);
    border: 2px solid var(--select-search-border);
    color: var(--select-search-text);
    border-radius: 3px;
    outline: none;
    font-family: var(--select-search-font);
    font-size: 12px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 40px;
    letter-spacing: 0.01rem;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
}

.select-search-is-multiple .select-search-input {
    margin-bottom: -2px;
}

.select-search-is-multiple .select-search-input {
    border-radius: 3px 3px 0 0;
}

.select-search-input::-webkit-search-decoration,
.select-search-input::-webkit-search-cancel-button,
.select-search-input::-webkit-search-results-button,
.select-search-input::-webkit-search-results-decoration {
    -webkit-appearance:none;
}

.select-search-input[readonly] {
    cursor: pointer;
}

.select-search-is-disabled .select-search-input {
    cursor: not-allowed;
}

.select-search-container:not(.select-search-is-disabled).select-search-has-focus .select-search-input,
.select-search-container:not(.select-search-is-disabled) .select-search-input:hover {
    border-color: var(--select-search-selected);
}

.select-search-select {
    background: var(--select-search-background);
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    border: 2px solid var(--select-search-border);
    overflow: auto;
    max-height: 360px;
}

.select-search-container:not(.select-search-is-multiple) .select-search-select {
    position: absolute;
    z-index: 2;
    top: 45px;
    right: 0;
    left: 0;
    border-radius: 3px;
    display: none;
}

.select-search-container:not(.select-search-is-multiple).select-search-has-focus .select-search-select {
    display: block;
}

.select-search-has-focus .select-search-select {
    border-color: var(--select-search-selected);
}

.select-search-options {
    list-style: none;
    padding: 0px;
}

.select-search-option,
.select-search-not-found {
    display: block;
    height: 20px;
    width: 100%;
    padding: 0 16px;
    background: var(--select-search-background);
    border: none;
    outline: none;
    color: var(--select-search-text);
    font-size: 12px;
    text-align: left;
    letter-spacing: 0.01rem;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.select-search-option:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background: transparent !important;
}

.select-search-is-highlighted,
.select-search-option:not(.select-search-is-selected):hover {
    background: var(--select-search-highlight);
}

.select-search-is-selected {
    font-weight: bold;
    color: var(--select-search-selected);
}

.select-search-group-header {
    font-size: 12px;
    text-transform: uppercase;
    background: var(--select-search-border);
    color: var(--select-search-subtle-text);
    letter-spacing: 0.1rem;
    padding: 10px 16px;
}

.select-search-row:not(:first-child) .select-search-group-header {
    margin-top: 10px;
}

.select-search-row:not(:last-child) .select-search-group-header {
    margin-bottom: 10px;
}

.clear-button-searcher{
    padding-left: 10px;
    color: #b12121;
    font-size: 23px;
    cursor: pointer;
}

.container-searcher-general{
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}