.menu-collapse{
  overflow: hidden;
  max-width: 0;
  transition: max-height 0.5s ease-out;
}
.menu-open{
  max-width: 100%;
}
.center{
  margin: 0 auto !important;
}
.chk-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.width-50-percent{
  max-width: 50%;
}
.btn-delete-horario {
  font-size: 0.75rem !important;
  float: right;
  margin-top: 15px;
}
.btn-round{
  border-radius: 60%;
}
.padding-top-50{
  padding-top: 50px;
}
.padding-top-10{
  padding-top: 10px;
}
.padding-top-15{
  padding-top: 15px;
}
.padding-top-20{
  padding-top: 20px;
}
.padding-bottom-20{
  padding-bottom: 20px;
}
.padding-left-5{
  padding-left: 5px;
}
.padding-left-10{
  padding-left: 10px;
}
.margin-top-10{
  margin-top: 10px;
}
.margin-top-15{
  margin-top: 15px;
}
.margin-top-45{
  margin-top: 45px;
}
.margin-top-48{
  margin-top: 48px;
}
.margin-top-50{
  margin-top: 50px;
}
.margin-bottom-45{
  margin-bottom: 45px;
}
.margin-bottom-10{
  margin-bottom: 10px;
}
.margin-bottom-15{
  margin-bottom: 15px;
}
.margin-top-30{
  margin-top: 30px;
}
.margin-top--45{
  margin-top: -45px;
}
.margin-right-20-percent{
  margin-right: 20%;
}
.margin-right-5{
  margin-right: 5px;
}
.margin-left-10{
  margin-left: 10px;
}
.display-none{
  display: none !important;
}
.display-block{
  display: block;
}
.sidebar-content, .sidebar {
  background: #2e56a6;
}

.float-inline-start{
  float: inline-start;
}

.sidebar-dropdown .sidebar-item.active .sidebar-link{  background: #3e424a;
  background-color: #3066c5;
  color: #fff;

}

.sidebar-item.active .sidebar-link:hover, .sidebar-item.active>.sidebar-link {
  background-color:#3066c5 ;
}

.text-center{
  text-align: center;
}

.font-size-80{
  font-size: 80px;
}

.pointer{
  cursor: pointer;
}

.table-layout-fixed{
  table-layout: fixed;
}

.tabla-curso-notas{
    display: block;
    overflow: auto;
    overflow-y: auto;
    width: 100%;
}

.tabla-curso-notas tr:hover {
  background-color: #eaeaea !important;
}


.text-table-one-line{
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header-fixed thead {
  position: sticky;
  display: inline-block;
  top: 0;
}

.float-none{
  float: none!important;
}

.width-100-percent{
  width: 100% !important;
}

.justify-content-end{
  justify-content: flex-end;
}