/* Estilos generales */
.icon-hover {
    font-size: 0.8em;
    color: grey;
    transition: color 0.3s ease-in-out; /* Para un efecto de transición suave */
  }
  
  .icon-hover:hover {
    color: blue; /* El color que desees al hacer hover */
  }

.students-description {
    font-size: 16px;
    color: #666; /* Color de texto secundario */
}


.student-table-container {
    background-color: #f7f7f7; /* Color de fondo principal */
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Sombra suave */
    max-width: 100vw;
}

.student-table-controls {
    align-items: center;
}

/* Estilos para botones */
.btn-NuevoEstudiante {
    background-color: #3f80ea;
    color: #fff; /* Color de texto del botón primario */
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    padding: 6px 10px;
    cursor: pointer;
}

.btn {
    font-size: 1rem;
}
.btn:hover{
    filter: brightness(85%);
}

.btn-DesactivarEstudiante {
    background-color: #e5a54b;;
    color: #fff !important; /* Color de texto del botón secundario */
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    padding: 6px 10px;
    cursor: pointer;
}
.btn-NuevoEstudiante:hover, .btn-DesactivarEstudiante:hover {
    filter: brightness(85%); /* Reducir brillo al pasar el mouse */
}

/* Asegurándonos de que el grupo de botones esté alineado a la derecha */
.student-table-controls .btn-group {
    margin-left: 10px;
}

/* Podrías agregar más estilos personalizados si lo necesitas, 
   como márgenes específicos entre los botones, etc. */
.student-table-controls .btn-Estudiante {
    margin-right: 10px;
}


/* Estilos para la tabla */
.students-datatable {
    background-color: #fff; /* Color de fondo de la tabla */
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Sombra suave para la tabla */
}
/* Estilo para el botón de editar */
.btn-edit {
    background-color: #3f80ea; /* Color de fondo amarillo */
    color: #fff; /* Texto en color blanco */
    border: none; /* Sin borde */
    border-radius: 4px; /* Bordes redondeados */
    cursor: pointer; /* Cursor al pasar el mouse */
}

/* Estilo para el botón de maximizar */
.btn-maximize {
    background-color: #bbd94e; /* Color de fondo azul */
    color: #fff; /* Texto en color blanco */
    border: none; /* Sin borde */
    border-radius: 4px; /* Bordes redondeados */
    cursor: pointer; /* Cursor al pasar el mouse */
}

/* Estilo para el botón de desactivar */
.btn-deactivate {
    background-color: #d9534f; /* Color de fondo rojo */
    color: #fff; /* Texto en color blanco */
    border: none; /* Sin borde */
    border-radius: 4px; /* Bordes redondeados */
    cursor: pointer; /* Cursor al pasar el mouse */
}

/* Cambiar color de fondo al pasar el mouse sobre los botones */
.btn-edit:hover,
.btn-maximize:hover,
.btn-deactivate:hover {
    filter: brightness(85%); /* Reducir brillo al pasar el mouse */
}


/* Estilos para la barra de búsqueda */


/* Personalizar la barra de desplazamiento para navegadores basados en WebKit (Chrome, Safari, etc.) */
::-webkit-scrollbar {
    width: 6px;  /* Ancho de la barra vertical */
    height: 6px;  /* Altura de la barra horizontal */
  }
  
  ::-webkit-scrollbar-thumb {
    background: #64646449;  /* Color del deslizador */
    border-radius: 5px;   /* Bordes redondeados del deslizador */
  }
  
  ::-webkit-scrollbar-track {
    background: #e0e0e0;  /* Color de fondo de la barra */
    border-radius: 5px;   /* Bordes redondeados de la barra */
  }
  
  .btn-matricula {
    background-color: #4CAF50; /* Color de fondo verde */
    color: white; /* Texto blanco */
    padding: 10px 20px; /* Padding alrededor del texto */
    border: none; /* Sin borde */
    border-radius: 5px; /* Bordes redondeados */
    cursor: pointer; /* Cursor como un puntero */
    font-size: 16px; /* Tamaño de la fuente */
    margin: 10px; /* Margen alrededor del botón */
}

/* Estilos generales para la tabla */
.table-corporate {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    color: #6c757d; /* Color del texto */
}

/* Cabecera de la tabla */
.table-corporate thead th {
    color: #fff;
    background-color: #64748b;
}

/* Celdas de la tabla */
.table-corporate td, .table-corporate th {
    vertical-align: middle;
    border-radius: 0.2rem;
    background-color: #fff;

}

/* Filas de la tabla al pasar el mouse */
.table-corporate tbody tr:hover td {
    background-color: #cbd5e1 !important;
}



/* Estilos para los bordes de las celdas */
.table-corporate th, .table-corporate td {
    border-bottom: 1px solid #dee2e6;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* Evita que el texto se ajuste a la línea siguiente */
}

/* Estilo para la columna de nombres en la tabla */
.table-corporate th.nombre-estudiante,
.table-corporate td.nombre-estudiante {
    width: 200px; /* Ajusta esto según tus necesidades */
    max-width: 280px; /* Asegura que la columna no exceda el ancho establecido */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* Evita que el texto se ajuste a la línea siguiente */
}



/* Estilo para las columnas de conceptos */
.table-corporate th.concepto, 
.table-corporate td.concepto, .table-corporate th.mes {
    min-width: 50px; /* Ajusta esto según tus necesidades */
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* Estilo para la fila de total */
.fila-total td{
    font-weight: bold; /* Aplica negrita al texto de la fila */
    align-items: right;
    background-color: #e2e8f0 !important;
}
---------------------------
procesos j 
.input-full-width {
    width: 210%; /* Hace que el input ocupe el 100% del ancho de su contenedor */
}