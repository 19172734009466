
/* CustomTableStyles.css */

.custom-table .table-hover tbody tr:hover {
    background-color: #DEE1EC;
  }
  
  .custom-table thead th {
    white-space: nowrap;
    background-color: #ddeafc;
    color: #3f80ea;
    font-size: 1rem; /* Tamaño adaptable para encabezados */
    text-align: left;
    padding: 12px; /* Espacio interno para celdas del encabezado */
    border: 1px solid var(--bs-dark-border-subtle); /* Color de borde para el encabezado */

}



  .table-notas-head {
    background-color: #f9f9f9;
}

.head-logro-preescolar {
  min-width: 165px; /* o el ancho que necesites */
  white-space: nowrap; /* Asegura que el texto no se envuelva */
}

  
  .custom-table tbody td {
    font-size: 0.875rem; /* Tamaño adaptable para el contenido */
    color: #000;
  }
  
  .custom-table tbody td,
  .custom-table thead th {
    padding: 8px; /* Espaciado interno para mejor legibilidad */
    /* Otros estilos... */
  }
  .custom-table .student-name-column {
    min-width: 200px; /* Ajusta este valor según necesites */
  }
  
  
  .custom-table tbody tr:hover td {
    cursor: pointer;
    background-color: #DEE1EC;
  }
  
  .custom-table .table-responsive {
    background-color: #ffffff;
    overflow-x: auto; /* Permite desplazamiento horizontal en pantallas pequeñas */
    border-top: 1px solid #c8d4e8;
    
  }
  
  .custom-table .pagination {
    background-color: #ffffff;
    font-size: 14px;
    padding: 8px;
  }

  .custom-table .form-control {
    font-size: 0.875rem; /* Tamaño adaptable para inputs */
    padding: 0.375rem 0.75rem;
    margin: auto; /* Centra el input en la celda */
    display: block;
    width: 90%; /* Ancho del input ajustado al tamaño de la celda */
    text-align: center;
}

.table-notas .fa-circle-plus {
    color: #28a745;
    cursor: pointer;
}

/* Ajustes para dispositivos más pequeños */
@media screen and (max-width: 768px) {
    .custom-table thead {
        display: none;

    }
}
----------------------------------
/* Estilo de la tabla de notas adaptado al esquema de colores de la plantilla general */
.div-table-notas {
    overflow-x: auto;
    margin-bottom: 20px;
}

.table-notas {
    width: 100%;
    border-collapse: collapse;
    background-color: var(--bs-light-bg-subtle); /* Color de fondo general de la tabla */
}


.table-notas tbody td {
    text-align: left;
    padding: 8px; /* Espacio interno para celdas del cuerpo */
    color: var(--bs-dark-text-emphasis); /* Color de texto para el cuerpo */
    border: 1px solid var(--bs-dark-border-subtle); /* Color de borde para el cuerpo */
    font-size: 0.825rem; /* Tamaño de texto para el cuerpo */
}

.table-notas tbody tr:nth-child(odd) {
    background-color: var(--bs-light-bg-subtle); /* Color de fondo para filas impares */
}

.table-notas tbody tr:hover {
    background-color: #DEE1EC;
}

.table-notas .celda-estudiante,
.table-notas td {
    max-width: none; /* Se elimina el ancho máximo para permitir flexibilidad */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Mejoras de responsividad */
@media (max-width: 768px) {
    .table-notas, .table-notas thead, .table-notas tbody, .table-notas th, .table-notas td, .table-notas tr {
    }

    .table-notas thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .table-notas td {
        position: relative;
        padding-left: 50%;
        border: none;
        border-bottom: 1px solid var(--bs-dark-border-subtle); /* Ajuste de borde para celdas en modo responsivo */
        text-align: right;
    }

    .table-notas td:before {
        content: attr(data-label);
        position: absolute;
        left: 10px;
        top: 0;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        font-weight: bold;
    }
}

.table-notas .form-control {
    font-size: 0.875rem;
    padding: 0.375rem 0.75rem;
    border: 1px solid var(--bs-dark-border-subtle);
    border-radius: 0.25rem;
    height: auto;
    text-align: center; /* Asegura que el texto esté centrado */
}
