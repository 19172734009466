/* Estilos comunes para todos los menús desplegables móviles */
.dropdown-movil,
.dropdown-movil-rol,
.dropdown-movil-child,
.dropdown-movil-user {
  position: absolute;
  width: auto; /* Ajusta al contenido */
  max-width: 80%; /* Limita el ancho */
  top: 100%; /* Despliega hacia abajo */
  transform: translateX(-50%); /* Centra respecto al activador para mejor visibilidad */
  right: 0; /* Alinea a la derecha para evitar cortes */
}

/* Ajustes específicos para el menú de 'child' */
.dropdown-movil-child {
  left: auto; /* Usa 'auto' para ajustes automáticos */
  transform: translateX(0); /* No aplicar desplazamiento */
}

/* Ajustes específicos para el menú 'rol' */
.dropdown-movil-rol {
  left: auto; /* Ajuste para 'rol', si es necesario */
  transform: translateX(0); /* No aplicar desplazamiento */
}

/* Ajustes específicos para el menú 'user' */
.dropdown-movil-user {
  left: auto; /* Ajuste para 'user', mejora la visibilidad */
  transform: translateX(0); /* No aplicar desplazamiento */
}

/* Ajustes para dispositivos muy pequeños */
@media (max-width: 480px) {
  .dropdown-movil,
  .dropdown-movil-rol,
  .dropdown-movil-child,
  .dropdown-movil-user {
    max-width: 90%; /* Más ancho en pantallas pequeñas para mejor accesibilidad */
    transform: translateX(-50%); /* Centrado en pantallas pequeñas */
  }
}

/* Asegurando que el menú desplegable no se extienda fuera de la pantalla */
.dropdown-menu-end {
  right: 0; /* Asegura que el menú no se extienda fuera de la vista visible */
}
