.dashboard-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}


.card-estudiante-info {
    text-align: center;
    border-radius: 20px;
    padding: 5px;
    width: 100%;
}

.foto-estudiante {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #bbd94e;
}

.nombre-estudiante {
    font-size: 1.2em;
    margin: 10px 0;
}

.info-estudiante {
    font-size: 1em;
    color: #666;
}

.dashboard-card {
    border-radius: 20px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    text-align: center;
}

.icono-dashboard {
    font-size: 2em;
    margin-bottom: 10px;
}

.dashboard-card h3 {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 5px;
}

.dashboard-card p {
    font-size: 1em;
    color: #666;
}


@media screen and (max-width: 768px) {
    /* Estilos para dispositivos móviles */
    .dashboard-container {
        flex-direction: column;
    }

    .dashboard-card {
        /* Ajustes para que las tarjetas se vean bien en dispositivos móviles */
    }
    .m-margin-top-10{
      margin-top: 10px;
    }
}


--------------------------------------------
.informacion-estudiante-container {
    background-color: #f0f4f8; /* Fondo más claro para destacar las tarjetas */
    padding: 20px;
    border-radius: 12px; /* Bordes más redondeados */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Sombra más suave */
    max-width: 100%; /* Aprovecha todo el ancho en dispositivos móviles */
    margin: auto;
}

.informacion-basica {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra más pronunciada para las tarjetas */
}

.informacion-basica span {
    display: flex; /* Usa flex para alinear iconos y texto */
    align-items: center; /* Centra los elementos verticalmente */
    margin: 10px 0;
}

.informacion-basica .fa-icon {
    color: #3b5998; /* Color azul colegial */
    margin-right: 10px;
    width: 24px; /* Asegura que los iconos tengan el mismo ancho */
}
.subtitulo {
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}


@media screen and (max-width: 768px) {
    .informacion-estudiante-container {
        padding: 10px; /* Menos padding en dispositivos móviles */
    }
    .m-margin-top-10{
      margin-top: 10px;
    }
}
-----------------------------
.observaciones-asistencia-container {
    padding: 20px;
}
.observaciones-asistencia-container {
    position: relative;
    /* Otros estilos que puedas necesitar */
  }
  .asistencia-container{
        display: flex;
        flex-wrap: wrap; /* Esto permitirá que las tarjetas pasen a la siguiente línea en pantallas pequeñas */
        justify-content: space-around; /* Esto distribuirá el espacio alrededor de las tarjetas */
        align-items: center; /* Esto alineará las tarjetas verticalmente */  
  }

  .asistencia-card {
    flex: 0 1 calc(25% - 10px); /* Ajusta el porcentaje y el margen según necesites */
    margin: 5px;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: white; /* Cambia esto según necesites */
  }

  @media (max-width: 600px) { /* O el punto de ruptura que prefieras */
    .asistencia-card {
        flex: 0 1 100%;
    }
}
.lista-observaciones {
    margin-top: 20px;
}

.observacion-item {
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    padding: 10px;
    display: flex;
    align-items: center;
  }
  
  .foto-profesor {
    border-radius: 50%;
    margin-right: 10px;
    width: 50px; /* O el tamaño que prefieras */
    height: 50px; /* Mantén el aspecto cuadrado */
  }
  
  .observacion-info {
    flex-grow: 1;
  }
.observacion-info p {
    font-size: 0.9rem;
    margin: 5px 0;
}

.observacion-info small {
    font-size: 0.8rem;
    color: #666;
}

.btn-back {
    display: inline-block;
    margin-bottom: 30px;
    background-color: #d1e8ff;
    color: #333;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
}


  .back-to-dashboard {
    position: relative;
    top: 30px;
    left: 15px;
    z-index: 1000;
    background-color: #fff; /* O el color que prefieras */
    padding: 9px;
    border-radius: 50%;
  }
  
  /* Media query para asegurar que el botón no interfiera en pantallas pequeñas */
  @media (max-width: 600px) {
    .observacion-item {
      flex-direction: column;
      align-items: start;
    }
  
    .foto-profesor {
      margin-bottom: 10px;
    }
  
    .observacion-info h4, .observacion-info p, .observacion-info small {
      margin: 0;
    }
  }
----------------------------------------------
.notas-estudiante-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
  }
  .asignatura-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px;
    width: calc(50% - 20px); /* Ajusta el ancho de las tarjetas para que dos quepan en una línea */
    transition: transform 0.3s ease;
  }
  
  .asignatura-card:hover {
    transform: translateY(-5px);
  }
  
  .progress {
    background-color: #f2f2f2;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  .progress-bar {
    background-color: #4caf50; /* O el color que prefieras */
    border-radius: 5px;
  }
  
  /* Estilos para los textos y titulos dentro de las tarjetas */
  .asignatura-card h3 {
    color: #333;
    margin-bottom: 10px;
  }
  
  .asignatura-card p {
    color: #666;
    font-size: 14px;
    line-height: 1.6;
  }
  
/* Estilos para ajustar el diseño en pantallas más pequeñas */
@media (max-width: 768px) {
    .asignatura-card {
      width: calc(100% - 20px); /* Las tarjetas ocuparán todo el ancho en pantallas pequeñas */
    }
  }
  
--------------------------------------
/* DashboardEstudiante.css */
/* Estilo del contenedor principal */
.contenedor-principal {
  display: flex;
  justify-content: space-between; /* Espacia los elementos uniformemente */
  align-items: flex-start; /* Alinea los elementos al inicio de su eje transversal */
  margin: 20px;
}

/* Estilo para el contenedor de las barras de progreso y la información del estudiante */
.notas-estudiante-container {
  margin: 20px;
  flex: 1; /* Ocupa el espacio necesario */
}

/* Estilos para las barras de progreso */
.lista-periodo {
  min-width: 60%;
  min-height: 100%;
  background-color: #f2f2f2;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;

}

.lista-asignatura {
  cursor: pointer;
  padding: 5px;
}

.lista-asignatura:hover {
  background-color: #e0e0e0;
}

.progress-bar-container {
  margin-top: 10px;
}

.progress {
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  line-height: 20px;
  color: white;
  text-align: center;
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
}

.bg-superior { background-color: #4caf50; } /* Verde */
.bg-alto { background-color: #2196f3; } /* Azul */
.bg-basico { background-color: #ffc107; } /* Amarillo */
.bg-bajo { background-color: #f44336; } /* Rojo */

/* Estilos para la tabla de notas */
.tabla-notas-fija {
  flex: 1;  /* Aumenta la proporción de espacio que ocupa la tabla */
  min-width: 60%;
  border-collapse: collapse;
  margin-left: auto; /* Asegura que la tabla se mueva hacia la derecha */

}

.tabla-notas-fija th, .tabla-notas-fija td {
  border: 1px solid #ddd;
  padding: 8px;
}

.tabla-notas-fija th {
  background-color: #2196f3;
  color: white;
}

.tabla-procesos div {
  min-width: 160px;
  padding: 5px 0; /* Espaciado dentro de cada celda para evaluaciones y notas */
}
.tabla-procesos{
  border-radius: 15px !important;
}

.contenedor-principal-notas{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start;
}
@media (max-width: 768px) {
  .tabla-notas-fija {
  flex: 1;
  min-width: 100%;
  }
  }
---------------------------------
vista de asistencia y observaciones
.observaciones-asistencia-container-pc {
  font-family: 'Arial', sans-serif; /* Puedes cambiar la fuente según prefieras */
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: #333;
}

.back-to-dashboard-pc {
  color: #007bff;
  text-decoration: none;
  font-size: 1.2em;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.back-to-dashboard-pc:hover {
  text-decoration: underline;
}

.estudiante-info-panel-pc {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.foto-estudiante-pc {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
}

.info-estudiante-pc {
  font-size: 1.2em;
}

.asistencia-container-pc {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 20px;
}

.asistencia-card-pc {
  flex: 1 1 45%;
  background-size: cover;
  color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  text-align: center;
}

.asistencia-card-pc h3 {
  margin-bottom: 10px;
}

.lista-observaciones-pc {
  margin-top: 20px;
}

.observacion-item-pc {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  align-items: center;
  padding: 15px;
  margin-bottom: 20px;
}

.foto-profesor-pc {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.observacion-info-pc h4 {
  margin: 0;
  color: #007bff;
}

.observacion-info-pc p {
  margin: 5px 0;
}

.observacion-info-pc small {
  color: #666;
}
---------------------------------------
horarioestudianetPc
/* HorarioEstudiantePc.css */
.horario-estudiante-container {
  padding: 20px;
  background-color: var(--bs-gray-100);
}

.btn-volver-dashboard {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: var(--bs-primary);
  font-size: 16px;
  text-decoration: none;
}

.btn-volver-dashboard:hover {
  text-decoration: underline;
}

.grid-semana {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding: 20px 0;
}

.columna-dia {
  width: 240px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.columna-dia:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.2);
}

.titulo-dia {
  font-size: 18px;
  color: var(--bs-primary);
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}

.tarjeta-clase {
  background-color: var(--bs-white);
  padding: 15px;
  margin-bottom: 15px;
  border-left: 4px solid var(--bs-primary);
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.tarjeta-clase:hover {
  background-color: var(--bs-gray-200);
}

.nombre-asignatura {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.horario-clase {
  font-size: 12px;
  color: var(--bs-dark);
}

/* Estilo para la scrollbar */
.semana-scroll::-webkit-scrollbar {
  height: 8px;
}

.semana-scroll::-webkit-scrollbar-track {
  background: var(--bs-gray-200);
  border-radius: 4px;
}

.semana-scroll::-webkit-scrollbar-thumb {
  background: var(--bs-primary);
  border-radius: 4px;
}
