.cardcate-card {
  margin: 0;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 10px;
  width: 170px; /* Aumentar el ancho para acomodar el contenido como en la segunda imagen */
  min-height: 120px; /* Ajustar la altura mínima */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Cambio para posicionar el contenido */
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra más suave */
  border: none; /* Quitar el borde para un look más limpio */
  transition: box-shadow 0.3s ease-in-out;
}

.cardcate {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Ajustar el espacio entre las tarjetas */
  justify-content: flex-start; /* Cambiar a flex-start para alinear las tarjetas a la izquierda */
}


.cardcate-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra más pronunciada al pasar el ratón */
    text-decoration: none;
    background-color: #DEE1EC;
  }


/* Para la tipografía y tamaños de fuente */
.cardcate-title {
  font-size: 1rem;
  color: #333; /* Color oscuro para el texto */
  margin-bottom: 5px;
}



/* Estilos para los iconos, si los hay */
.cardcate-icon {
    font-size: 2.5rem; /* Aumentar el tamaño del ícono si es necesario */
    margin-bottom: 15px;
    color: #666; /* Color más suave para el ícono */
}


