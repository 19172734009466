
.acta-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border: 1px solid #b4b2b2; /* Borde delgado */
  margin-bottom: 5px;
}

.acta-header .escudo {
  width: 90px; /* Ajusta según sea necesario */
  height: auto;
}

.acta-header .header-content {
  text-align: center;
  flex-grow: 1;
}

.acta-header h2 {
  margin-top: 0;
  color: #222222; /* Color oscuro para el título */
  font-size: 24px;

}

.acta-header p {
  margin: 5px 0;
  color: #222222; /* Color gris para los detalles */
  font-size: 14px;
}
.section-title {
  font-size: 16px;
      text-align: center;
      color: #222222; /* Color gris para los detalles */

      
}

.incidencia-info, .incidencia-desc, .incidencia-implicados {
  border: 1px solid #b4b2b2; /* Borde delgado */
  border-radius: 3px; /* Bordes redondeados */
  padding: 40px; /* Espaciado interno */
  margin-bottom: 5px; /* Espaciado entre secciones */
  color: #222222; /* Color gris para los detalles */

}
.datos-incidencia-table {
  border-collapse: collapse;
  width: 100%;
}

.datos-incidencia-table th, .datos-incidencia-table td {
  color: #222222;
  border: none; /* Elimina los bordes internos */
  padding: 2px; /* Ajusta el padding para espaciar el contenido dentro de las celdas */
}

.incidencia-title, .categoria-title, .tipo-title, .fecha-title, .periodo-title, .notifica-title {
  font-weight: bold; /* Hace que los títulos de las columnas sean en negrita */
  text-align: left; /* Alinea el texto a la izquierda */
}

.incidencia-content, .categoria-content, .tipo-content, .fecha-content, .periodo-content, .notifica-content {
  text-align: left; /* Alinea el texto a la izquierda para los contenidos */
}


.section-title {
  margin-bottom: 5px; /* Espaciado debajo del subtítulo */
}

.header-p {
  color: #222222; /* Color oscuro para el párrafo */
  line-height: 1.5; /* Altura de línea para mejorar la legibilidad */
}

.tabla-implicados {
  width: 100%; /* Ancho completo para la tabla */
  border-collapse: collapse; /* Colapsar bordes de la tabla */
  margin-top: 5px; /* Espaciado arriba de la tabla */
  margin: 1px;
}

.tabla-implicados th, .tabla-implicados td {
  border: 1px solid #b4b2b2; /* Borde delgado */
  border-radius: 4px;
  padding: 2px 5px; /* Espaciado interno en celdas */
  text-align: left; /* Alineación del texto a la izquierda */
  color: #222222; /* Color gris para los detalles */
  font-weight: 600;
}

.tabla-implicados th {
  color: #222222; /* Texto azul oscuro para encabezados */
}
.acta-footer {
  padding: 20px 10px; /* Ajusta el padding según tus necesidades */
  border: 1px solid #ccc; /* Opcional, añade un borde superior para separar el footer */
  border-radius: 4px;
}

.footer-content {
  max-width: 1200px; /* Ajusta según el ancho máximo deseado */
  margin: 0 auto; /* Centra el contenido en la página */
  }

.firmas {
  display: grid;
   /* grid-template-columns: repeat(auto-fill, minmax(180px, 1fr)); Crea columnas que se ajusten automáticamente */
  grid-template-columns: auto auto auto;
  gap: 20px; /* Espacio entre firmas */
  justify-content: center; /* Centra las firmas horizontalmente */
  align-items: center; /* Alinea las firmas verticalmente */

}

.firma {
  display: flex; /* Usa flexbox para un mejor control del alineamiento */
  flex-direction: column; /* Organiza los elementos en una columna vertical */
  align-items: center; /* Centra los elementos horizontalmente */
  text-align: center; /* Asegura que el texto esté centrado */
  padding: 10px; /* Espaciado alrededor de cada firma para evitar que se vean apretadas */
  margin-bottom: 20px; /* Espacio adicional en la parte inferior de cada firma */
}

.firma-imagen {
  width: 100px;
  height: auto; /* Ajusta la altura automáticamente para mantener la proporción */
  margin-bottom: 10px; /* Espacio entre la imagen y el texto */
}

.firma p {
  margin: 4px 0; /* Ajusta el margen entre párrafos para evitar espacios excesivos */
}



/* Estilos generales para la sección del informe */
.incidencia-info-boletin {
  background-color: #fff;
  border: 1px solid #b4b2b2;
  border-radius: 4px;
  margin-bottom: 5px;
  padding: 10px;
}


/* Estilos para el encabezado de la sección */
.info-header-boletin {
  margin-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

/* Estilo para el título de la sección */
.section-title-boletin {
  font-size: 15px;
  text-align: center;
  color: #333;
  margin: 0;
  font-weight: bold;
}
.datos-incidencia-table th.info-title-boletin,
.datos-incidencia-table td.info-content-boletin {
    padding: 2px; /* Ajusta el padding según la necesidad */
    text-align: left; /* Alinea el texto a la izquierda */
}

.datos-incidencia-table th.info-title-boletin {
    font-weight: bold; /* Texto en negrita para los títulos */
}

.datos-incidencia-table td.info-content-boletin {
    color: #495057; /* Color de texto para el contenido */
}


/* Resaltar el título del ítem */
.info-title-boletin::after {
  content: ':';
}

/* Extender el nombre del estudiante y del tutor */
.info-item-boletin .info-content-boletin {
  flex-grow: 1;
  text-align: left;
  margin-left: 10px;
}

/* Asegurar que el nombre del estudiante y el nombre del tutor ocupen el máximo espacio */
.info-item-boletin.estudiante, .info-item-boletin.tutor {

}

.escala-valorativa-container {
  margin-top: 5px;
  border: 1px solid #b4b2b2;
  border-radius: 4px;
  margin-bottom: 5px;
  padding: 10px;
  position: relative;
}

.escala-valorativa-header {
  display: flex;
  justify-content: center; /* Centra el título */
  margin-bottom: 5px;
}

.escala-valorativa-title {
  font-weight: bold;
  color: #222222;
  font-size: 12px;
}


.escala-valorativa-body {
  display: flex;
  justify-content: space-evenly; /* Distribuye los elementos equitativamente */
  flex-wrap: wrap; /* Permite el salto de fila */
  align-items: center; /* Alinea verticalmente */
}

.escala-valorativa-body-prescolar {
  display: flex;
  justify-content: space-evenly; /* Distribuye los elementos equitativamente */
  align-items: center; /* Alinea verticalmente */
}

.escala-item {
  padding: 5px;
  margin: 2px;
  border: 1px solid #b4b2b2;
  border-radius: 4px;
  font-size: 0.7rem;
  color: #333;
  flex: 1 1 45%; /* Ocupa cerca del 45% para que dos items quepan en una fila */
  text-align: center; /* Centra el texto */
}


/* Ajustes de respuesta para dispositivos más pequeños */
@media (max-width: 768px) {
  .escala-item {
    padding: 3px;
    font-size: 10px; /* Más pequeño para ajustar en pantallas estrechas */
    min-width: 100px; /* Tamaño mínimo más pequeño para pantallas pequeñas */
  }
}



/* Estilos para la tabla de áreas académicas */
.table-custom-boletin {
  width: 100%;
  border-collapse: collapse;
  
}

.table-custom-boletin th, .table-custom-boletin td {
  border: 1px solid #b4b2b2;
  padding: 2px 2px 2px 5px;
  text-align: left;
}

/* Estilos para el encabezado de área */
.area-header {
  background-color: #f2f2f2;
  font-weight: bold;
}

/* Estilos para las filas de la tabla */
.table-custom-boletin tr:nth-child(even){background-color: #f9f9f9;}

/* Estilos para los encabezados de las asignaturas */
.table-custom-boletin th {
  background-color: #f2f2f2;
  color: #444333;
}

.historico-periodos {
  margin-top: 15px;
  border: 1px solid #b4b2b2;
  border-radius: 4px;
  padding: 15px;
  width: auto; /* Esto permite que la tabla se ajuste al contenido */
  margin-right: auto; /* Alinea la tabla a la izquierda con margen automático a la derecha */
  margin-bottom: 15px;

}

.historico-title {
  text-align: left;
  font-weight: bold;
  margin-bottom: 15px;
}

.table-historico {
  width: 100%; /* Ajusta la tabla al contenido */
  border-collapse: collapse;
  margin-bottom: 15px;
}

.table-historico th, .table-historico td {
  border: 1px solid #ddd;
  padding: 4px; /* Reducido para una tabla más pequeña */
  text-align: left;
  font-size: 10px; /* Tamaño de fuente más pequeño */
}

.table-historico thead th {
  background-color: #f2f2f2;
  font-size: 12px; /* Tamaño de fuente ligeramente más grande para encabezados */
}

.table-historico tr:nth-child(even) {
  background-color: #f9f9f9;
}

.estado-promocion {
  text-align: right;
  font-weight: bold;
}



/* Ajustes para pantallas más pequeñas */
@media (max-width: 768px) {
  .table-historico td, .table-historico th {
    font-size: 8px; /* Tamaño de fuente más pequeño para dispositivos móviles */
  }
}


.recuperacion {
  display: inline-block; /* Hace que el span se comporte como un bloque, permitiendo el padding */
  padding: 2px 4px; /* Espaciado interno alrededor del texto */
  margin-top: 4px; /* Espacio sobre el span para separarlo de la nota principal */
  border: 1px solid #666; /* Borde sólido */
  border-radius: 5px; /* Bordes redondeados */
  font-size: 0.8em; /* Tamaño de fuente más pequeño */
  color: #666; /* Color del texto */
  background-color: #f9f9f9; /* Fondo ligeramente gris para destacar */
}


.table-corte-preventivo {
  width: 100%;
  border-collapse: collapse;
}

.table-corte-preventivo th, .table-corte-preventivo td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.table-corte-preventivo thead {
  background-color: #f9f9f9;
}

.table-corte-preventivo tr:nth-child(even) {
  background-color: #efefef;
}

.corte-preventivo {
  border-collapse: collapse;
  width: 100%;
  max-width: 600px; /* Adjust the max width as per your requirement */
  margin: auto;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.corte-preventivo th, 
.corte-preventivo td {
  border: 1px solid #ddd;
  padding: 8px 16px;
  text-align: left;
  color: #555;
}

.corte-preventivo th {
  background-color: #fafafa;
  color: #333;
  font-weight: 600;
}

.corte-preventivo tr:nth-child(even) {
  background-color: #f2f2f2;
}

.corte-preventivo tr:hover {
  background-color: #eaeaea;
}

.corte-preventivo tbody tr:first-child td {
  font-weight: bold;
  text-align: center;
}

.acta-atencion-titulo {
  text-align: center; /* Centrado del título del acta */
  font-weight: bold;
  margin-bottom: 0.25in; /* Margen debajo del título */
}

.text-center{
  text-align: center!important;
}

.escale-title{
  padding-right: 30px !important;
}

.asignatura-title{
  min-width: 20px!important;
  max-width: 40px!important;
}
.asignatura-title-preescolar{
  min-width: 180px!important;
}



.corte-preventivo-lista {
  border-collapse: collapse;
  width: auto; /* Ajusta el ancho según el contenido para que sea compacta */
  margin-left: 0; /* Alineación al lado izquierdo */
  margin-bottom: 20px; /* Espaciado inferior para separar de otros elementos */
  background-color: #fff; /* Fondo blanco para la tabla */
  box-shadow: none; /* Sin sombra para un aspecto más profesional y limpio */
}

.corte-preventivo-lista th, 
.corte-preventivo-lista td {
  border: none; /* Sin bordes internos para hacerla compacta y limpia */
  padding: 5px 10px; /* Reducción del padding para compactar */
  text-align: left; /* Alineación del texto a la izquierda */
  color: #555; /* Color del texto */
  font-size: 0.8rem; /* Tamaño de fuente reducido */
}

.corte-preventivo-lista th {
  background-color: transparent; /* Fondo transparente para el encabezado */
  color: #333; /* Color del texto para el encabezado */
  font-weight: 600; /* Texto en negrita para el encabezado */
}

.corte-preventivo-lista tbody tr:first-child td {
  font-weight: bold; /* Primer fila en negrita para destacar */
  text-align: center; /* Alineación centrada para la primera fila */
}
/* Contenedor principal */
.contenedor-informes {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
}

/* Estilos base para los contenedores de cada estudiante */
.mini-info-preventivo {
  flex: 0 0 calc(25% - 20px); /* Ajuste para hasta 4 elementos por fila */
  background: #f7f7f7; /* Fondo claro para todo el contenedor */
  border: 1px solid #dcdcdc; /* Borde sutil */
  margin-bottom: 20px;
  font-family: Arial, sans-serif; /* Fuente similar a la imagen */
}

/* Encabezado con fondo gris claro */
.encabezado-reportes {
  background-color: #eaeaea; /* Fondo gris claro */
  padding: 10px;
  border-bottom: 1px solid #dcdcdc; /* Borde separador */
}

/* Título del colegio y tipo de reporte */
.titulo-colegio, .tipo-reporte {
  margin: 0;
  margin-top: 10px; /* Espacio en la parte superior */
  margin-bottom: 10px; /* Espacio en la parte inferior */
  font-weight: bold; /* Negrita como en la imagen */
  color: #000; /* Texto oscuro */
  text-align: center; /* Centrado de texto */
}

/* Información del estudiante con fondo blanco */
.info-estudiante {
  background-color: #fff;
  padding: 10px;
  border-bottom: 1px solid #dcdcdc; /* Borde separador */
}

/* Nombre del estudiante y detalle del período */
.nombre-estudiante, .detalle-periodo {
  margin: 0;
  margin-top: 5px; /* Espacio en la parte superior */
  margin-bottom: 5px; /* Espacio en la parte inferior */
  text-align: center; /* Centrado de texto */
}

/* Estilos para la tabla */
.tabla-mini-info-preventivo {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.tabla-mini-info-preventivo td {
  border: none; /* Sin bordes en las celdas */
  padding: 5px 10px; /* Acolchado similar al de la imagen */
  text-align: center;
}

/* Alternar colores de fondo para las filas de la tabla */
.tabla-mini-info-preventivo tr:nth-child(odd) {
  background-color: #fff;
}

.tabla-mini-info-preventivo tr:nth-child(even) {
  background-color: #f0f0f0;
}

/* Estilo para los checkmarks */
.checkmark {
  text-align: center;
  font-size: larger; /* Tamaño más grande para los checkmarks */
}

/* Ajuste responsivo para pantallas más pequeñas */
@media (max-width: 1024px) {
  .mini-info-preventivo {
    flex-basis: 100%; /* Los contenedores ocuparán el 100% del ancho */
  }
}

/* Ajuste responsivo para que en pantallas más pequeñas se muestren menos columnas */
@media (max-width: 1200px) {
  .mini-info-preventivo {
    flex-basis: calc(50% - 20px); /* Dos contenedores por fila */
  }
}


/* Ajustes globales para la impresión */
@media print {
  
  * {
    
    margin: 0;
   
   
  }
  body {
    margin: 0;
    padding: 0;
    margin-top: -30px !important;
    

   
  }

  @page {
    size: 210mm 297mm;
      margin: 2mm 5mm 20mm 5mm;
      orphans: 0;
      widows: 0;
  
  }
  .title-aspecto-preescolar-tabla-dimensiones{
    font-weight: bold;
    color: #000;
  }

  .promedio-curso-vs-estudiante {
    page-break-inside: avoid; /* Evita que la sección se divida entre páginas */
    page-break-after: auto;  /* Permite un salto de página después de la sección si es necesario */
    border: 1px solid #ddd;  /* Opcional: añade un borde para distinguir la sección */
    padding: 10px;           /* Opcional: añade un poco de relleno para mejorar la legibilidad */
    margin-bottom: 20px;     /* Espacio antes de un posible salto de página */
  }

  .promedio-curso-vs-estudiante table {
    width: 100%; /* Asegura que la tabla use todo el ancho disponible */
  }

  .promedio-curso-vs-estudiante tr {
    page-break-inside: avoid; /* Evita cortes de página dentro de las filas */
    page-break-after: auto;  /* Permite un salto de página después de cada fila si es necesario */
  }
  
  .academic-areas {
    page-break-after: auto;  /* Inserta un salto de página después de la sección si es necesario */
    
  }

  .table-custom-boletin {
    width: 100%;  /* Asegura que la tabla use todo el ancho disponible */
    page-break-inside: auto; /* Permite cortes de página dentro de la tabla si es necesario */
    
  }

  .table-custom-boletin thead {
    display: table-row-group; /* Evita que el encabezado se repita en cada nueva página */
  }

  .table-custom-boletin tbody {
    display: table-row-group; /* Trata el cuerpo como un bloque continuo que puede dividirse */
  }

  .table-custom-boletin tr {
    page-break-inside: avoid; /* Evita cortes de página dentro de las filas */
    page-break-after: auto;  /* Permite un salto de página después de cada fila si es necesario */
  }
  



  .historico-periodos {
    page-break-inside: avoid; /* Evita que el contenido se divida entre páginas */
    page-break-after: auto;  /* Inserta un salto de página después de la sección si es necesario */
  }

  .table-historico {
    width: 100%;  /* Asegura que la tabla use todo el ancho disponible */
    page-break-inside: auto; /* Permite cortes de página dentro de la tabla si es necesario */
  }

  .table-historico thead {
    display: table-header-group; /* Asegura que el encabezado se repita en cada nueva página */
  }

  .table-historico tbody {
    display: table-row-group; /* Trata el cuerpo como un bloque continuo que puede dividirse */
  }

  .table-historico tr {
    page-break-inside: avoid; /* Evita cortes de página dentro de las filas */
    page-break-after: auto;  /* Permite un salto de página después de cada fila si es necesario */
  }


  .acta-header .escudo {
    width: 90px;
    height: auto;
}

.acta-header .header-content {
    text-align: center;
    flex-grow: 1;
}



.acta-header p, .info-content, .header-p {
    font-size: 10px; /* Reducir el tamaño de fuente para impresión */
}


.firma-input {
    font-size: 10px; /* Ajustar el tamaño de la fuente para los campos de firma */
}


  .firma p {
    font-size: 9px;
  }
  #sidebar{
      display: none;
  }

  .escala-valorativa-body {
    justify-content: space-between;
  }

  .escala-item {
    font-size: 9pt; /* Ajusta el tamaño de fuente para la impresión */
  }


  /* Opcional: Ocultar otros elementos que no deseas imprimir */
  .non-printable {
    display: none;
  }
  .table-historico th, .table-historico td {
    padding: 2px; /* Espacio interior más pequeño para la impresión */
    font-size: 9px; /* Tamaño de fuente ajustado para la impresión */
  }

  .table-historico thead th {
    font-size: 9px; /* Tamaño de fuente ajustado para la impresión */
  }

  /* Asegura que la tabla no ocupe más del 100% del ancho de la página */
  .table-historico {
    width: 100%;
    max-width: none;
  }

  .acta-container, .academic-areas, .escala-valorativa-container, .historico-periodos, .incidencia-info-boletin {
    box-shadow: none;
    margin: 0;
    padding: 10px; /* Añade un poco de espacio alrededor del contenido */
  }

  
  .table-corte-preventivo, .table-historico, .table-custom-boletin {
    font-size: 10.5px; /* Ajusta el tamaño de fuente para la impresión */
  }
  
  /* Elimina los márgenes del documento para utilizar todo el ancho disponible */
  .table-corte-preventivo, .table-historico, .table-custom-boletin {
    width: 100%;
    max-width: none;
  }


  .info-title-boletin, .info-content-boletin {
    font-size: 10px;
  }
  
  .footer{
    display: none;
  }
  
}