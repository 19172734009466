#detalles-asignatura-container {
    font-family: var(--bs-body-font-family);
    color: var(--bs-dark-text-emphasis);
    background-color: var(--bs-light-bg-subtle);
    border: 1px solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    box-shadow: var(--bs-box-shadow);
    overflow: hidden; /* Asegura que todo el contenido quede dentro del borde redondeado */
  }
  
  #cabecera-detalles {
    background-color: var(--bs-white);
    color: var(--bs-dark);
    padding: 1rem;
    border-bottom: 1px solid var(--bs-border-color);
  }
  
  #titulo-notasPanel {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
  
  #nota-definitiva {
    font-size: 1rem;
    color: var(--bs-gray-600);
  }

  .proceso-detalle  {
    padding: 1rem;
  }
  
 
  .descripcion-column, .nota-column {
    text-align: left;
    padding: 8px;
    border: 1px solid var(--bs-border-color);
  }
  
  .descripcion-column, .nota-column {
    background-color: var(--bs-gray-100);
    color: var(--bs-dark);
  }
  .nota-column {
    width: 100px; /* Asigna un ancho fijo a las columnas de nota */
  }
  
  .fila-evaluacion .descripcion-evaluacion, .fila-evaluacion .nota-evaluacion {
    padding: 8px;
  }
  
  
  .fecha-evaluacion {
    font-size: 0.8rem;
    color: var(--bs-gray-600);
  }
  
  #nota-final-asignatura {
    font-weight: bold;
    padding: 1rem;
    text-align: right;
    background-color: var(--bs-light-bg-subtle);
    border-top: 1px solid var(--bs-border-color);
  }  

  @media print {
    .lista-asig-print {
      display: none;
    }
  }