.body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
}
.recibo-header img.logo {
    margin-top: 30px;
    width: 100px;
    height: 100px;
}
.recibo-header img.pagado {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 100%;
    height: auto;		
    opacity: 0.5; /* Ajusta la transparencia */
    z-index: -0.4; /* Coloca la imagen de fondo */
}
.recibo-container {
    max-width: 650px;
    margin: 10px auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #dedede;
}
.recibo-header {
    text-align: center;
    position: relative;
}
.recibo-header img.logo {
    width: 100px;
}
.recibo-header img.pagado {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 150px;
}
.recibo-header img.anulado {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 150px;
}
.recibo-info {
    margin-top: 20px;
    padding: 10px;
    background-color: #f2f2f2;
    border: 1px solid #e1e1e1;
    text-align: center;
}
.recibo-info strong {
    font-weight: bold;
}
.recibo-body {
    margin-top: 15px;
    margin-bottom: 20px;
}
.recibo-body table {
    width: 100%;
    border-collapse: collapse;
}
.recibo-body table th,
.recibo-body table td {
    text-align: left;
    padding: 5px;
    border: 1px solid #e1e1e1;
}
.recibo-body table th {
    background-color: #f2f2f2;
    font-weight: bold;
}
    
.recibo-footer {
    text-align: center;
    margin-top: 20px;
    font-size: 0.8em;
}


    @media print {
        .body{
            background-color: #fff;
            zoom: 0.9;
        }
        .recibo-container {
            width: 20cm;
            height: 14.5cm;
            position: relative;
            padding: 1cm; /* Ajuste del padding para el contenido interno */
            margin: auto; /* Centrar el recibo en la hoja */
            page-break-after: always; /* Asegura que después del recibo se inserte un salto de página */
          }
          .recibo-header {
            display: flex;
            align-items: center;
            justify-content: start; /* Logo a la izquierda y texto a la derecha */
            margin-bottom: 0.5cm;
          }
          .recibo-header img.logo {
            width: 3cm; /* Tamaño del logo */
            height: auto; /* Mantener la proporción del logo */
            margin-right: 1cm; /* Espacio entre el logo y el texto */
          }
          .recibo-header .recibo-header-info {
            text-align: left;
          }
          .recibo-header img.pagado {
            position: fixed;
            top: 280px;
            right: 150px;
            width: 6cm;
            height: auto;
            opacity: 0.3;
            z-index: -0.4;
          }
          .recibo-info, .recibo-body table, .recibo-footer {
            background-color: #f2f2f2; /* Color de fondo para las celdas */
          }

        

          .recibo-body table th {
            background-color: #eaeaea; /* Color de fondo gris claro para las celdas TH */
          }
          .btn{
            display: none !important;    }

            #lateralMenu{
                display: none !important;
            }

            .datatable-recibosdepago, .students-datatable{
                display: none !important;
                box-shadow:none !important;
            }
        
            .student-table-container{
                box-shadow:none !important;
        
            }

            .card{
                box-shadow:none !important;

            }
            .modal-footer{
                border-top: none;
            }
            .modal-xl-custom {
                max-width: 100%;
              }
              #sidebar{
                display: none;
            }
            .modal-header{
                display: none;
            }
            .filter-container{
                display: none;
            }
            .nav{
                display: none;
            }
      }