.build {
    position: relative;
    height: 80vh;
    overflow: hidden;
}

.build-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.build-content{
    margin-top: 30px;
    text-align: center;

}

