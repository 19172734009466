/* Estilos generales para la tabla */
.table-prevent {
    width: 100%;
    border-collapse: collapse; /* Colapsa los bordes de la tabla para un diseño más limpio */
    font-size: 0.8rem; /* Tamaño de fuente más pequeño para un aspecto profesional */
  }
  
  .table-prevent, .th-table-prevent, .td-table-prevent {
    border: 1px solid #dee2e6; /* Color de borde suave para las celdas y la tabla */
  }
  
  .thead-prevent {
    background-color: #f8f9fa; /* Fondo claro para el encabezado para distinguirlo */
  }
  
  .th-table-prevent, .td-table-prevent {
    padding: 0.5rem; /* Espaciado interno para las celdas para evitar que el contenido se amontone */
    text-align: left; /* Alineación del texto a la izquierda por defecto */
  }
  
  .th-table-prevent.celda-estudiante {
    min-width: 120px; /* Anchura mínima para la celda del estudiante para mejorar la legibilidad */
  }
  
  /* Estilos para hacer la tabla responsiva */
  @media screen and (max-width: 768px) {
    .table-prevent {
      display: block;
      overflow-x: auto; /* Permite desplazamiento horizontal en pantallas pequeñas */
    }
  
    .thead-prevent, .tbody-table-prevent, .tr-table-prevent, .td-table-prevent, .th-table-prevent {
      display: block;
    }
  
    .tr-table-prevent {
      margin-bottom: 1rem; /* Espaciado entre filas para visualización tipo bloque */
    }
  
    .th-table-prevent, .td-table-prevent {
      text-align: right; /* Alinea el texto a la derecha para mejorar la visualización en modo bloque */
      padding-left: 50%; /* Espaciado a la izquierda para alinear el contenido de las celdas */
    }
  
    .th-table-prevent.celda-estudiante, .td-table-prevent {
      text-align: left; /* Alineación especial para la celda del estudiante */
    }
  }
  
  /* Ajustes visuales adicionales */
  .form-check-input {
    cursor: pointer; /* Cambia el cursor para indicar que el elemento es interactivo */
  }
  
  [data-tooltip-id] {
    cursor: help; /* Cambia el cursor para indicar que el elemento ofrece información adicional */
  }
  
  /* Asegúrate de adaptar y expandir estos estilos según las necesidades específicas de tu proyecto */
  